import React from 'react';
import About from '../components/About';
import Layout from '../layout/pl';


import { graphql } from 'gatsby';

const IndexPage = (props) => {
	// console.log('TCL: IndexPage -> props', props.data);

	return (
		<Layout {...props}>
			<About {...props} />
		</Layout>
	);
};

export const query = graphql`
	query {
		site {
			siteMetadata {
				title
				languages {
					langs
					defaultLangKey
				}
			}
		}
		inside: file(relativePath: { eq: "inside-about.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1300) {
					...GatsbyImageSharpFluid
				}
			}
		}
		lady: file(relativePath: { eq: "fbpost.png" }) {
			childImageSharp {
				# Specify a fluid image and fragment
				# The default maxWidth is 800 pixels
				fluid(maxWidth: 500) {
					...GatsbyImageSharpFluid
				}
			}
		}
		background: file(relativePath: { eq: "inside_compress.jpg" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 1920) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`;

export default IndexPage;
