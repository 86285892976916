// import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getCurrentLangKey } from 'ptz-i18n';

const SiteData = (props) => {
	// console.log('SiteData', props);
	const data = useStaticQuery(graphql`
		query sitedataquery {
			site {
				siteMetadata {
					title
					languages {
						langs
						defaultLangKey
					}
				}
			}
		}
	`);

	const url = props.pathname;
	const { langs, defaultLangKey } = data.site.siteMetadata.languages;
	const langKey = getCurrentLangKey(langs, defaultLangKey, url);
	const currentLang = langKey === 'pl' ? '' : `/${langKey}`;
	// console.log('SiteData curre', currentLang);

	return {
		currentLang: currentLang,
		languages: data.site.siteMetadata
	};
};

export default SiteData;
