import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';
import Firma from '../images/inside.jpg';
import Lips from '../images/icon/lips.png';
import ToothEstetic from '../images/icon/shiny-tooth.png';
import ToothFake from '../images/icon/fake-tooth.png';
import ToothFilled from '../images/icon/filled-tooth.png';
import SiteData from './SiteData';


import { isMobile } from 'react-device-detect';

// import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

const About = (props) => {
	const { currentLang } = SiteData(props.location);

	// console.log('about', currentLang);
	// console.log('about', languages, currentLang);
	console.log('about', props);
	// console.log('about', props.data);
	// console.log('about', langKey);
	return (
		<div>
			{/* <Img fixed="../images/fbpost.png" /> */}
			{/* <Img fixed={Lady} /> */}
			{/* <Img
				className="headshot"
				fluid={props.data.fb.childImageSharp.fluid}
				alt=""
			/> */}

			<header className="content">
				{/* <Img
				className="headshot"
				fixed={props.data.inside.childImageSharp.fixed}
				alt=""
			/>
			<Img
				className="headshot"
				fluid={props.data.fb.childImageSharp.fluid}
				alt=""/> */}
				{/* <div className="main__header"> */}
				<img src={Firma} alt="wewnatrz firmy" className="only-mobile img" />
				{isMobile ? (
					<div className="main__header">
						<div className="main__photo">
							{/* <Img fluid={props.data.inside.childImageSharp.fluid} alt="" 
					style={{
						position: "absolute"
					}}/> */}
							{/* <Img fixed={props.data.fb.childImageSharp.fixed} alt="" /> */}
						</div>
						<div style={{ overflow: 'hidden' }}>
							<ul className="main__header--lista">
								<Link to={`${currentLang}/uslugi/stomatologia-estetyczna`}>
									<li className="main__header--li">
										<img className="main__header--img" src={ToothEstetic} alt="usta" />
										<FormattedMessage id="menu.services.aestheticDentistry" />
									</li>
								</Link>
								<Link to={`${currentLang}/uslugi/implantologia`}>
									<li className="main__header--li">
										<img className="main__header--img" src={ToothFake} alt="usta" />
										<FormattedMessage id="menu.services.implantology" />
									</li>
								</Link>
								<Link to={`${currentLang}/uslugi/kompleksowa-opieka`}>
									<li className="main__header--li">
										<img className="main__header--img" src={ToothFilled} alt="usta" />
										<FormattedMessage id="menu.services.comprehensiveDentalCare">
											{(txt) => {
												return currentLang === '' ? (
													<div>
														<p>Kompleksowa opieka </p>
														<p>i radiologia stomatologiczna</p>
													</div>
												) : (
													txt
												);
											}}
										</FormattedMessage>
									</li>
								</Link>
								<Link to={`${currentLang}/uslugi/medycyna-estetyczna`}>
									<li className="main__header--li">
										<img className="main__header--img" src={Lips} alt="usta" />
										<FormattedMessage id="menu.services.aestheticMedicine" />
									</li>
								</Link>
							</ul>
						</div>
					</div>
				) : (
					<BackgroundImage
						tag="section"
						className="main__header"
						fluid={props.data.background.childImageSharp.fluid}
					>
						<div className="main__header">
							<div className="main__photo">
								{/* <Img fluid={props.data.inside.childImageSharp.fluid} alt="" 
						style={{
							position: "absolute"
						}}/> */}
								{/* <Img fixed={props.data.fb.childImageSharp.fixed} alt="" /> */}
							</div>
							<div style={{ overflow: 'hidden' }}>
								<ul className="main__header--lista">
									<Link to={`${currentLang}/uslugi/stomatologia-estetyczna`}>
										<li className="main__header--li">
											<img className="main__header--img" src={ToothEstetic} alt="usta" />
											<FormattedMessage id="menu.services.aestheticDentistry" />
										</li>
									</Link>
									<Link to={`${currentLang}/uslugi/implantologia`}>
										<li className="main__header--li">
											<img className="main__header--img" src={ToothFake} alt="usta" />
											<FormattedMessage id="menu.services.implantology" />
										</li>
									</Link>
									<Link to={`${currentLang}/uslugi/kompleksowa-opieka`}>
										<li className="main__header--li">
											<img className="main__header--img" src={ToothFilled} alt="usta" />
											<FormattedMessage id="menu.services.comprehensiveDentalCare">
												{(txt) => {
													return currentLang === '' ? (
														<div>
															<p>Kompleksowa opieka </p>
															<p>i radiologia stomatologiczna</p>
														</div>
													) : (
														txt
													);
												}}
											</FormattedMessage>
										</li>
									</Link>
									<Link to={`${currentLang}/uslugi/medycyna-estetyczna`}>
										<li className="main__header--li">
											<img className="main__header--img" src={Lips} alt="usta" />
											<FormattedMessage id="menu.services.aestheticMedicine" />
										</li>
									</Link>
								</ul>
							</div>
						</div>
					</BackgroundImage>
				)}
			</header>
			<section id="about">
				<div className="content">
					<FormattedMessage id="aboutUs.about">{(txt) => <h2>{txt}</h2>}</FormattedMessage>
					<div className="section__content">
						{/* <img src={Lady} alt="Zdrowy uśmiech" /> */}
						<Img
							className="section__image lazyload"
							fluid={props.data.lady.childImageSharp.fluid}
							alt="Zdrowy uśmiech"
						/>
						<div>
							<FormattedMessage
								id="aboutUs.about.description"
								values={{
									span: (msg) => {
										// console.log('msg', msg);
										return (
											<span key={msg} className="blue">
												{msg}
											</span>
										);
									}
								}}
							>
								{(...chunks) => <p>{chunks}</p>}
							</FormattedMessage>

							<FormattedMessage id="aboutUs.dedicatedDentistry">
								{(txt) => <h2 className="brown">{txt}</h2>}
							</FormattedMessage>
							<FormattedMessage
								id="aboutUs.dedicatedDentistry.description"
								values={{
									span: (msg) => {
										return (
											<span key={msg} className="blue">
												{msg}
											</span>
										);
									}
								}}
							>
								{(...chunks) => <p>{chunks}</p>}
							</FormattedMessage>
							<br />
							<FormattedMessage
								id="aboutUs.dedicatedDentistry.manifest"
								values={{
									pbrown: (msg) => {
										return (
											<p key={msg} className="brown">
												{msg}
											</p>
										);
									}
								}}
							>
								{(...chunks) => <div>{chunks}</div>}
							</FormattedMessage>
						</div>
					</div>
					<div className="section__content">
						<div>
							<FormattedMessage id="aboutUs.prestigeAsStandard">
								{(txt) => <h3 className="blue">{txt}</h3>}
							</FormattedMessage>
							<FormattedMessage
								id="aboutUs.prestigeAsStandard.description1"
								values={{
									span: (msg) => {
										return (
											<span key={msg} className="blue">
												{msg}
											</span>
										);
									}
								}}
							>
								{(...chunks) => <p>{chunks}</p>}
							</FormattedMessage>
							<br />
							<FormattedMessage
								id="aboutUs.prestigeAsStandard.description2"
								values={{
									span: (msg) => {
										return (
											<span key={msg} className="blue">
												{msg}
											</span>
										);
									}
								}}
							>
								{(...chunks) => <p>{chunks}</p>}
							</FormattedMessage>
							<br />
							<FormattedMessage
								id="aboutUs.prestigeAsStandard.description3"
								values={{
									span: (msg) => {
										return (
											<span key={msg} className="blue">
												{msg}
											</span>
										);
									}
								}}
							>
								{(...chunks) => <p>{chunks}</p>}
							</FormattedMessage>
							<br />
							<FormattedMessage id="aboutUs.prestigeAsStandard.description4">
								{(txt) => <p>{txt}</p>}
							</FormattedMessage>
							<br />
							<FormattedMessage
								id="aboutUs.prestigeAsStandard.description5"
								values={{
									span: (msg) => {
										return (
											<span key={msg} className="blue">
												{msg}
											</span>
										);
									}
								}}
							>
								{(...chunks) => <p>{chunks}</p>}
							</FormattedMessage>
						</div>
						{/* <img src={InsidePhoto} alt="Wygląd wewnętrzny firmy" /> */}

						<Img
							className="section__image lazyload"
							fluid={props.data.inside.childImageSharp.fluid}
							alt="Wygląd wewnętrzny firmy"
						/>
					</div>
				</div>
			</section>
		</div>
	);
};

export default About;

// export const query = graphql`
// 	query {
// 		file(relativePath: { eq: "images/fbpost.png" }) {
// 			childImageSharp {
// 				# Specify the image processing specifications right in the query.
// 				# Makes it trivial to update as your page's design changes.
// 				fixed(width: 125, height: 125) {
// 					...GatsbyImageSharpFixed
// 				}
// 			}
// 		}
// 	}
// `;
